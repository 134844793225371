import { useSelector } from "react-redux";
import { Routes, Route, Navigate, RoutesProps } from "react-router-dom";
import { RootState } from "../config/Store";
import Main from "../Main";
import { UserState } from "../reducer/AuthReducer";
import Changepin from "../Screens/ChangePin/ChangePin";
import ConsentDetail from "../Screens/ConsentDetail/ConsentDetail";
import ConsentList from "../Screens/ConsentList/ConsentList";
import Editprofile from "../Screens/EditProfile/EditProfile";
import Home from "../Screens/Home/Home";


import Login from "../Screens/Login/Login";
import ManageAccounts from "../Screens/ManageAccounts/ManageAccounts";
import ManageConsent from "../Screens/ManageConsent/ManageConsent";
import SearchingAccount from "../Screens/SearchingAccount/SearchingAccount";
import Signup from "../Screens/Signup/Signup";
import Success from "../Screens/Success/Success";
import VerifyOtp from "../Screens/VerifyOtp/VerifyOtp";
import SearchBank from "../Screens/SearchBank/SearchBank";
import ProtectedRoute from "./ProtectedRoute";
import AddAccount from "../Screens/AddAccount/AddAccount";
import AccountsOtpScreen from "../Screens/AccountsOtpScreen/AccountsOtpScreen";
import HelpAndFeedback from "../Screens/HelpAndFeedback/HelpAndFeedback";
import VerifyPin from "../Screens/VerifyPin/VerifyPin";

const Navigation = () => {

    const login: any = useSelector<RootState, UserState>((state) => state.userLogin);

    interface ProtectedRouteProps extends RoutesProps {
        isAuthenticated: boolean;
        authenticationPath: string;
    }

    const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
        isAuthenticated: sessionStorage.getItem("token") != null,
        authenticationPath: "/login",
    };
    return (
        <>
            <div>
                <Routes>
                    <Route path="/" element={<Navigate replace to="/login" />} />
                    <Route
                        path="/login"
                        element={
                            defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (
                                <Navigate replace to="/login" />
                            ) : (
                                <Login />
                            )
                        }
                    />
                    <Route
                        path="/signup"
                        element={
                            defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (
                                <Navigate replace to="/home" />
                            ) : (
                                <Signup />
                            )
                        }
                    />
                    <Route
                        path="/verify-otp"
                        element={
                            defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (
                                <Navigate replace to="/home" />
                            ) : (
                                <VerifyOtp />
                            )
                        }
                    />
                    <Route
                        path="/verify-pin"
                        element={
                            defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (
                                <Navigate replace to="/home" />
                            ) : (
                                <VerifyPin />
                            )
                        }
                    />
                    <Route
                        path="/success"
                        element={
                            defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (
                                <Navigate replace to="/home" />
                            ) : (
                                <Success />
                            )
                        }
                    />
                    <Route
                        path="/searching-account"
                        element={
                            defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (
                                <Navigate replace to="/home" />
                            ) : (
                                <SearchingAccount />
                            )
                        }
                    />
                    {/* PROTECTED ROUTES */}
                    <Route
                        path="/"
                        element={
                            <ProtectedRoute
                                {...defaultProtectedRouteProps}
                                outlet={<Main />}
                            />
                        }
                    >
                        <Route path="/home" element={<Home />} />
                        <Route path="/manage-consent" element={<ManageConsent />} />
                        <Route path="/manage-accounts" element={<ManageAccounts />} />
                        <Route path="/edit-profile" element={<Editprofile />} />
                        <Route path="/change-pin" element={<Changepin />} />
                        <Route path="/consents-list" element={<ConsentList />} />
                        <Route path="/consent/:id" element={<ConsentDetail />} />
                        <Route path="/search-bank" element={<SearchBank />} />
                        <Route path="/add-account" element={<AddAccount />} />
                        <Route path="/accounts-otp" element={<AccountsOtpScreen />} />
                        <Route path="/help-and-feedback" element={<HelpAndFeedback />} />
                    </Route>
                </Routes>
            </div>
        </>
    )
}

export default Navigation;