import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/style.css';
import './AddAccount.scss'
import Accordion from 'react-bootstrap/Accordion';
import hdfcimg from '../../assets/images/hdfc.svg';
import sbiimg from '../../assets/images/sbi.svg';
import { useNavigate, useLocation } from "react-router-dom";
import HelperService from '../../utility/HelperService';
import WebService from '../../utility/webService';
import { toast } from 'react-toastify';
import DefaultImage from '../../assets/images/default.svg';

const Addaccount = () => {
    let location = useLocation();
    const [accounts, setAccounts] = useState<any[]>([])
    const [isLoading, setLoading] = useState(false)
    let history = useNavigate();

    useEffect(() => {
        if (location.state != null) {
            for (var i in location.state.data) {
                location.state.data[i].isChecked = false;
            }
            setAccounts(location.state.data)
        }
    }, [location]);

    const onLink = () => {
        var array = [];
        for (var i in accounts) {
            if (accounts[i].isChecked == true) {
                array.push((accounts[i]))
            }
        }
        if (array.length == 0) {
            toast.error('Please select atleast one account.')
        } else {
            setLoading(true)
            WebService.postAPI({
                action: `/aaclient/account/link/${location.state.id}`,
                body: {
                    Accounts: array
                }
            })
                .then((res: any) => {
                    setLoading(false)
                    if (res.AuthenticatorType == 'TOKEN') {
                        history('/accounts-otp', { state: { refNumber: res.RefNumber, name: location.state.name } })
                    } else {
                        history('/home')
                    }
                })
                .catch((e) => {
                    setLoading(false)
                    toast.error(e.response.data.errorMsg)
                })
        }
    }

    const onCheck = (index: any) => {
        for (var i in accounts) {
            if (index == i) {
                accounts[i].isChecked = !accounts[i].isChecked
            } else if (index == 'ALL') {
                accounts[i].isChecked = true
            }
        }
        setAccounts([...accounts])
    }

    return (
        <>
            <div className='oms-addaccount-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-sm-6 mx-auto my-auto pt-4'>
                            <h4 className="d-none d-sm-block">Discovered Accounts</h4>
                            {/* <div className="d-none d-sm-block disc-acct">Discovered Accounts</div> */}
                            {
                                accounts.length > 0 &&
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header><img src={location.state && (location.state.image ? location.state.image : DefaultImage)} alt="HDFC" height={20} width={20} className='ml-2' />&nbsp; {location.state && location.state.name}</Accordion.Header>
                                        <Accordion.Body>
                                            <div className='select-all-text pointer' onClick={() => onCheck('ALL')}>Select All</div>
                                            {
                                                accounts?.map((res, index) => {
                                                    return (
                                                        <div className="row mb-3 d-flex align-items-center" key={index}>
                                                            <div className="col-6">
                                                                <input type="checkbox" name="" className="mr-2" onClick={() => onCheck(index)} checked={res.isChecked} />
                                                                <span className="osm-ac-no">{res.maskedAccNumber}</span>
                                                            </div>
                                                            <div className="col-6 text-right">
                                                                <div className="osm-saving-wrap">
                                                                    <div>
                                                                        <div className='add-account-type'>{res.accType && HelperService.getTitleCase(res.accType)}</div>
                                                                        <div className="tc">{res.fiType && HelperService.getTitleCase(res.fiType)} </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            }
                            {
                                accounts.length == 0 &&
                                <>
                                    <h2 className='no-accounts'>No Accounts Found!</h2>
                                    <div className="row mt-5 d-flex justify-content-center">
                                        <div className="col-6">
                                            <button type="button" name="" value="Cancel" className="btns-cancel" onClick={() => window.history.back()}>Back</button>
                                        </div>
                                    </div>
                                </>
                            }

                            {
                                accounts.length > 0 &&
                                <div className="row mt-5">
                                    <div className="col-6">
                                        <button type="button" name="" value="Cancel" className="btns-cancel" onClick={() => window.history.back()}>Cancel</button>
                                    </div>
                                    <div className="col-6">
                                        <button type="button" name="" value="Proceed" className="btns" onClick={() => onLink()} disabled={isLoading}>
                                            {isLoading && <div className='om-btn-loader-m'></div>}Proceed</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Addaccount;