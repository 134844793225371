import axios from "axios";
import { toast } from "react-toastify";

// export const BASE_URL = "https://uat-aa.omsaa.com";

// export const BASE_URL = "https://uat-api.omsaa.com";

export const BASE_URL = "https://api.omsaa.com";

interface PropData {
    action: string;
    body?: any;
    isFormData?: boolean;
    isShowError?: boolean;
    id?: string;
}

const WebService = {
    getAPI: function (props: PropData) {
        return new Promise((resolve, reject) => {
            const headers = {
                "Content-Type": "application/json",
                "appLang": "en_US",
                Authorization: "Bearer " + sessionStorage.getItem("token"),
            };
            axios
                .get(`${BASE_URL}${props.action}`, {
                    headers: headers,
                })
                .then((response) => {
                    resolve(response?.data);
                })
                .catch((error) => {
                    if (error?.response?.data?.status == 401) {
                        sessionStorage.clear();
                        window.location.href = "/login";
                    }
                    props.isShowError ? reject(this.errorHandler(error)) : reject(error);
                });
        });
    },

    putAPI: function (props: PropData) {
        return new Promise((resolve, reject) => {
            var bodyFormData = new URLSearchParams();
            for (let key in props.body) {
                bodyFormData.append(key, props.body[key]);
            }
            const headers = {
                "Content-Type": "application/json",
                "appLang": "en_US",
                Authorization: "Bearer " + sessionStorage.getItem("token"),
            };
            axios.put(`${BASE_URL}${props.action}`, props.body, {
                headers: headers
            })
                .then((response) => {
                    resolve(response?.data);
                })
                .catch((error) => {
                    if (error?.response?.data?.status == 401) {
                        sessionStorage.clear();
                        window.location.href = "/login";
                    }
                    props.isShowError ? reject(this.errorHandler(error)) : reject(error);
                });
        })
    },

    postAPI: function (props: PropData) {
        return new Promise((resolve, reject) => {
            var bodyFormData = new URLSearchParams();
            for (let key in props.body) {
                bodyFormData.append(key, props.body[key]);
            }
            const headers = {
                "Content-Type": "application/json",
                "appLang": "en_US",
                Authorization: "Bearer " + sessionStorage.getItem("token"),
            };
            axios.post(`${BASE_URL}${props.action}`, props.body, {
                headers: headers
            })
                .then((response) => {
                    resolve(response?.data);
                })
                .catch((error) => {
                    if (error?.response?.data?.status == 401) {
                        sessionStorage.clear();
                        window.location.href = "/login";
                    }
                    props.isShowError ? reject(this.errorHandler(error)) : reject(error);
                });
        })
    },

    deleteAPI: function (props: PropData) {
        return new Promise((resolve, reject) => {
            const headers = {
                "Content-Type": "application/json",
                "appLang": "en_US",
                Authorization: "Bearer " + sessionStorage.getItem("token"),
            };
            axios
                .delete(`${BASE_URL}${props.action}`, {
                    headers: headers,
                })
                .then((response) => {
                    resolve(response?.data);
                })
                .catch((error) => {
                    if (error?.response?.data?.status == 401) {
                        sessionStorage.clear();
                        window.location.href = "/login";
                    }
                    props.isShowError ? reject(this.errorHandler(error)) : reject(error);
                });
        });
    },

    errorHandler: function (error: any) {
        if (error?.response) {
            error = error.response;
        }
     
        var errorMessage;
        if (!error || !error.status) {
            errorMessage = "Server Not Responding";
        } else if (error.status === 401) {
            sessionStorage.clear();
            window.location.href = "/login";
        } else if (error.status === 500) {
            errorMessage =
                (error &&
                    error.data &&
                    error.data.ErrorDetails &&
                    error.data.ErrorDetails.message) ||
                "An unkown exception has occur. Please Contact administrator";
        } else {
            errorMessage = error?.data?.error_description;
        }
        toast.error(errorMessage);
        return errorMessage;
    },
}

export default WebService;