import backarrowimg from '../../assets/images/back-arrow.svg'
import { Link, useNavigate } from 'react-router-dom';
import OTPInput from "react-otp-input";
import { useState } from 'react';
import WebService from '../../utility/webService';
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { USER_LOGOUT, setDataInRedux } from '../../action/CommonActions';

const Changepin = () => {
    const [pin, setPin] = useState<any>("");
    const [newPin, setNewPin] = useState<any>("");
    const [isLoading, setLoading] = useState(false)
    const dispatch: Dispatch<any> = useDispatch();
    let navigate = useNavigate();

    const handleChange = (value: any) => {
        setPin(value)
    }

    const handleNewChange = (value: any) => {
        setNewPin(value)
    }

    const onContinue = () => {
        if (pin.length < 6 && newPin.length < 6) {
            toast.error('Please enter correct pin')
        } else {
            setLoading(true)
            WebService.postAPI({
                action: `/aaclient/customer/pin/change`,
                body: {
                    oldPin: pin,
                    newPin: newPin
                }
            })
                .then((res: any) => {
                    setPin('')
                    setNewPin('')
                    setLoading(false)
                    toast.success(res.message)
                    navigate('/home')
                })
                .catch((e) => {
                    setPin('')
                    setNewPin('')
                    setLoading(false)
                    toast.error(e.response.data.errorMsg)
                    if (e.response.data.block) {
                        sessionStorage.clear()
                        localStorage.clear();
                        dispatch(setDataInRedux({ type: USER_LOGOUT, value: '' }));
                        navigate('/login')
                    }
                })
        }
    }

    const onForgotPin = () => {
        WebService.getAPI({
            action: `/aaclient/customer/pin/forgot`,
            body: null
        })
            .then((res: any) => {
                toast.success(res.message)
            })
            .catch((e) => {
                toast.error(e.response.data.errorMsg)
            })
    }

    return (
        <>
            <section className="mt100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-sm-12 mb-4">
                            <div className="white-bg d-block d-sm-none">
                                <Link to="/home" className="back-arrow"><img src={backarrowimg} alt="Back" /></Link>
                                <span>Change PIN</span>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 mx-auto my-auto">
                            <h4 className="d-none d-sm-block mt-4">Change PIN</h4>
                            <div className="tag-line pb-2">Enter your old PIN</div>
                            <form method="get" className="digit-group text-center" data-group-name="digits" data-autosubmit="false">
                                <div className="otp">
                                    <OTPInput
                                        onChange={handleChange}
                                        value={pin}
                                        inputStyle="otpInputStyle"
                                        className='otpbox'
                                        isInputSecure={true}
                                        numInputs={6}
                                        separator={<span></span>}
                                    />
                                </div>
                                <div className="">
                                    <div className="tag-line pb-2 mt-4">Enter Your New PIN</div>
                                    <div className="otp">
                                        <OTPInput
                                            onChange={handleNewChange}
                                            value={newPin}
                                            inputStyle="otpInputStyle"
                                            className='otpbox'
                                            isInputSecure={true}
                                            numInputs={6}
                                            separator={<span></span>}
                                        />
                                    </div>
                                </div>
                                <div className="form-group mt-5 text-center">
                                    <button type="button" name="" value="Verify" className="btns" onClick={() => onContinue()} disabled={isLoading}>
                                        {isLoading && <div className='om-btn-loader-m'></div>}Continue</button>
                                </div>
                                {/* <div className="text-center py-4 pointer"><a onClick={() => onForgotPin()} className="tc">Forgot PIN?</a></div> */}
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )


}

export default Changepin;