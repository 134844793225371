import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import HelperService from "../../utility/HelperService";
import pauseIcon from '../../assets/images/pause.png';
import closeIcon from '../../assets/images/close-icon.png';
import playIcon from '../../assets/images/play.png';
import WebService from "../../utility/webService";
import { toast } from "react-toastify";
import backarrowimg from '../../assets/images/back-arrow.svg';
import Loader from "../../Components/Loader/Loader";
import DefaultImage from '../../assets/images/default.svg';
import { Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { setDataInRedux, USER_LOGOUT } from "../../action/CommonActions";

const ConsentDetail = () => {
    let location = useLocation();
    let navigate = useNavigate();
    const [detail, setDetail] = useState<any>()
    const [isLoading, setLoading] = useState(false)
    const [acceptLoader, setAcceptLoader] = useState(false)
    const [accounts, setAccounts] = useState<any[]>([]);
    const [fipName, setFipName] = useState('');
    const [notifications, setNotifications] = useState<any[]>([]);
    const [show, setShow] = useState(false);
    const otpRef = useRef<any>();
    const [pin, setPin] = useState('');
    const [status, setStatus] = useState('');
    const dispatch: Dispatch<any> = useDispatch();

    useEffect(() => {
        if (location.state != null) {
            location.state && setDetail(location.state.data)
            if (location.state.data.userConsentStatus == null) {
                getAccounts()

            }
            getNotifications()
        }
    }, [location]);

    const getAccounts = () => {
        WebService.getAPI({
            action: `/aaclient/accounts-linked/LINKED/${location?.state?.data?.id}`,
            body: null
        })
            .then((res: any) => {
                // var array = [];
                setFipName(res.accountVOList.length > 0 && res.accountVOList[0].fipName)
                for (var i in res.accountVOList) {
                    if (res.accountVOList[i].accounts.length > 0) {
                        for (var j in res.accountVOList[i].accounts) {
                            res.accountVOList[i].accounts[j].isChecked = false;
                            // array.push(res.accountVOList[i].accounts[j])
                        }
                    }
                }
                // for (var i in array) {
                //     array[i].isChecked = false;
                // }
                setAccounts(res.accountVOList)
            })
            .catch((e) => {

            })
    }

    const getNotifications = () => {
        WebService.getAPI({
            action: `/aaclient/Consent/log/${location?.state?.data?.id}`,
            body: null
        })
            .then((res: any) => {
                setNotifications(res.list)
            })
            .catch((e) => {

            })
    }

    const onChangeStatus = (status: any) => {
        setLoading(true)
        WebService.postAPI({
            action: `/aaclient/Consent/${detail?.id}/${status}`,
            body: {
                pin: pin
            }
        })
            .then((res: any) => {
                setShow(false)
                setLoading(false)
                setPin('')
                toast.success(res.message)
                navigate("/manage-consent")
            })
            .catch((e) => {
                if (e?.response?.data?.block) {
                    sessionStorage.clear()
                    localStorage.clear();
                    dispatch(setDataInRedux({ type: USER_LOGOUT, value: '' }));
                    navigate('/login')
                }
                setLoading(false)
                toast.error(e.response.data.errorMsg)
            })
    }

    const onAcceptConsent = () => {
        var array = [];
        for (var i in accounts) {
            for (var j in accounts[i].accounts) {
                if (accounts[i].accounts[j].isChecked == true) {
                    array.push({ accRefNumber: accounts[i].accounts[j].accRefNumber, fipId: accounts[i].accounts[j].fipId })
                }
            }
        }
        if (array.length == 0) {
            toast.error('Please select atleast one account')
        } else {
            setAcceptLoader(true)
            WebService.postAPI({
                action: `/aaclient/Consent/Approved`,
                body: {
                    ConsentId: detail.id,
                    Accounts: array,
                    Pin: pin
                }
            })
                .then((res: any) => {
                    setShow(false)
                    setAcceptLoader(false)
                    setPin('')
                    toast.success(res.message)
                    navigate("/manage-consent")
                })
                .catch((e) => {
                    if (e?.response?.data?.block) {
                        sessionStorage.clear()
                        localStorage.clear();
                        dispatch(setDataInRedux({ type: USER_LOGOUT, value: '' }));
                        navigate('/login')
                    }
                    setAcceptLoader(false)
                    toast.error(e.response.data.errorMsg)
                })
        }
    }


    const onCheck = (index: any) => {
        for (var i in accounts) {
            if (index == i) {
                accounts[i].isChecked = !accounts[i].isChecked
            }
        }
        setAccounts([...accounts])
    }

    const handleChange = (value: any) => {
        setPin(value)
    }


    return (
        <>
            <Loader show={isLoading} />
            <section className="mt100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-sm-12">
                            <div className="white-bg d-block d-sm-none">
                                <a onClick={() => navigate("/manage-consent")} className="back-arrow"><img src={backarrowimg} alt="Back" /></a>
                                <span>Consents Details</span>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 mx-auto my-auto pt-3">
                            <h4 className="d-none d-sm-block py-2">Consents Details</h4>
                            <div className="border-btm">
                                <div><img src={detail?.logoUrl ? detail?.logoUrl : DefaultImage} alt="" className="pull-left mr-2 mt-2" width="36" height="36" />{detail?.title}<br /><small className="day-ago">{moment(detail?.createdDt).format('DD/MM/YYYY hh:mm A')}</small>
                                    {
                                        (detail?.userConsentStatus == 'READY' || detail?.userConsentStatus == 'ACTIVE') &&
                                        <span className="next-arrows pt-4">
                                            <a className="mr-2 cursor-pointer" onClick={() => {
                                                setStatus('PAUSED')
                                                setShow(true)
                                            }}><img src={pauseIcon} alt="Pause" width="40" height="40" /></a>
                                            <a className="cursor-pointer" onClick={() => {
                                                setStatus('REVOKED')
                                                setShow(true)
                                                // onChangeStatus('REVOKED')
                                            }}><img src={closeIcon} alt="Close" width="40" height="40" /></a>
                                        </span>
                                    }
                                    {
                                        detail?.userConsentStatus === "PAUSED" &&
                                        <span className="next-arrows pt-4">
                                            <a className="mr-2 cursor-pointer" onClick={() => {
                                                setStatus('ACTIVE')
                                                setShow(true)
                                                // onChangeStatus('ACTIVE')
                                            }}><img src={playIcon} alt="Pause" width="40" height="40" /></a>
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className="border-btm">
                                <div className="con-title">Consent Purpose</div>
                                <div className="dates">{detail && detail?.consentArtifact?.Purpose?.text}</div>
                                <div className="dates">{detail && detail?.consentArtifact?.Purpose?.Category?.type}</div>
                            </div>
                            <div className="border-btm">
                                <div className="con-title">Date of consent given</div>
                                <div className="dates">{detail && moment(detail?.consentArtifact?.consentStart).format('DD MMMM,YYYY')}</div>
                            </div>
                            <div className="border-btm">
                                <div className="con-title">Date of expire</div>
                                <div className="dates">{detail && moment(detail?.consentArtifact?.consentExpiry).format('DD MMMM,YYYY')}</div>
                            </div>
                            {
                                location?.state?.data?.consentStatus != 'PENDING' &&
                                <div className="border-btm">
                                    <div className="con-title">Last View Date</div>
                                    <div className="dates">{detail && moment(detail?.lastUseDateTime).format('DD MMMM,YYYY')}</div>
                                </div>
                            }
                            {
                                detail?.userConsentStatus != null &&
                                <div className="border-btm">
                                    <div className="con-title">Account taken</div>
                                    {
                                        detail?.accounts?.length > 0 && detail?.accounts?.map((res: any) => {
                                            return (
                                                <div className="dates">{res.maskedAccNumber}</div>
                                            )
                                        })
                                    }
                                </div>
                            }
                            <div className="border-btm">
                                <div className="con-title">Consent Mode</div>
                                <div className="dates">{detail && HelperService.getTitleCase(detail?.consentArtifact?.consentMode)}</div>
                            </div>

                            <div className="border-btm">
                                <div className="con-title">Fetch Type</div>
                                <div className="dates">{detail && HelperService.getTitleCase(detail?.consentArtifact?.fetchType)}</div>
                            </div>
                            {
                                (detail?.consentArtifact?.FIDataRange?.from || detail?.consentArtifact?.FIDataRange?.to) &&
                                <div className="border-btm">
                                    <div className="con-title">FI Date Range</div>
                                    <div className="dates">{detail && moment(detail.consentArtifact.FIDataRange.from).format('DD MMMM,YYYY')} - {detail && moment(detail.consentArtifact.FIDataRange.to).format('DD MMMM,YYYY')}</div>
                                </div>
                            }
                            {
                                detail?.consentUsagesHistory != null && location?.state?.data?.consentStatus != 'PENDING' &&
                                <div className="border-btm">
                                    <div className="con-title">Consent Usages History</div>
                                    {
                                        detail?.consentUsagesHistory?.length > 0 && detail?.consentUsagesHistory?.map((res: any) => {
                                            return (
                                                <div className="dates">{res && moment(res).format('DD MMMM,YYYY hh:mm A')}</div>
                                            )
                                        })
                                    }
                                </div>
                            }
                            {
                                location?.state?.data?.consentStatus != 'PENDING' &&
                                <div>
                                    {
                                        notifications.length > 0 && <div className="border-btm">
                                            <div className="con-title">Notifications</div>
                                            {
                                                notifications.length > 0 && notifications.map((res: any) => {
                                                    return (
                                                        <div className="d-flex justify-content-between mt-2">
                                                            <div className="dates notification-des">{res.description}</div>
                                                            <div className="dates" style={{ fontSize: 14 }}>{res.createdDate && moment(res.createdDate).format('DD MMMM,YYYY hh:mm A')}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            }
                            {
                                detail?.userConsentStatus == null &&
                                <div className="border-btm">
                                    <div className="con-title">Accounts</div>
                                    {
                                        accounts?.length > 0 && accounts?.map((res, index) => {
                                            return (
                                                <div >
                                                    <div className="account-type-title">{res.fipName}</div>
                                                    {
                                                        res.accounts.map((val: any, j: number) => {
                                                            return (
                                                                <>
                                                                    <div className="d-flex justify-content-between">
                                                                        <div>
                                                                            <div className="d-flex" key={index} style={{ height: 30 }}>
                                                                                <input type="checkbox" name="" className="mr-2" onClick={() => {
                                                                                    accounts[index].accounts[j].isChecked = !accounts[index].accounts[j].isChecked
                                                                                    setAccounts([...accounts])
                                                                                }} checked={val.isChecked} />
                                                                                <div className="dates" style={{ marginTop: 12 }}>{val.maskedAccNumber}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='add-account-type'>{val.fiType && HelperService.getTitleCase(val.fiType)}</div>
                                                                            <div className="tc" style={{ textAlign: 'end' }}>{val.accType && HelperService.getTitleCase(val.accType)}</div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        accounts.length == 0 &&
                                        <div>No accounts Found</div>
                                    }
                                </div>
                            }
                            {
                                detail?.userConsentStatus == null &&
                                <div className="row mt-5">
                                    <div className="col-6">
                                        <button type="button" name="" value="Cancel" className="btns-cancel" style={{ background: '#4ad295' }} onClick={() => {
                                            setStatus('ACCEPT')
                                            setShow(true)
                                            // onAcceptConsent()
                                        }} disabled={acceptLoader}>
                                            {acceptLoader && <div className='om-btn-loader-m'></div>}Accept</button>
                                    </div>
                                    <div className="col-6">
                                        <button type="button" name="" value="Proceed" className="btns" style={{ background: '#ff5454' }} onClick={() => {
                                            setStatus('REJECTED')
                                            setShow(true)
                                            // onChangeStatus('REJECTED')
                                        }} disabled={isLoading}>
                                            {isLoading && <div className='om-btn-loader-m'></div>}Reject</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header >
                    <Modal.Title>Verify PIN</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="sm-title pb-3">Enter PIN</div>
                    <form method="get" className="digit-group text-center mt-4" data-group-name="digits" data-autosubmit="false">
                        <div className="otp">
                            <OtpInput
                                ref={otpRef}
                                onChange={handleChange}
                                value={pin}
                                inputStyle="otpInputStyle"
                                className='otpbox'
                                isInputSecure={true}
                                numInputs={6}
                                shouldAutoFocus={true}
                                separator={<span></span>}
                            />
                        </div>
                        {/* <div className="resend mt-3 ">Resend Activation Code <span>00:{counter}</span></div> */}
                        {/* <div className="text-center mt-3"><a onClick={() => setShow(true)} className={'tc pointer'}>Forgot PIN</a></div> */}

                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex w-50'>
                        <button type="submit" name="" value="procced" className="btns-cancel" onClick={() => {
                            setShow(false)
                            setPin('')
                        }}>
                            Cancel</button>
                        <button type="submit" name="" value="procced" className="btns ml-3" onClick={() => {
                            if (pin.length < 6) {
                                toast.error('Please enter correct otp')
                            } else {
                                if (status == 'ACCEPT') {
                                    onAcceptConsent()
                                } else {
                                    onChangeStatus(status)
                                }
                            }
                        }}>
                            Verify</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ConsentDetail;