import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import hdfcimg from '../../assets/images/hdfc.svg';
import sbiimg from '../../assets/images/sbi.svg';
import WebService from '../../utility/webService';
import { useNavigate } from "react-router-dom";
import Loader from '../../Components/Loader/Loader';
import DefaultImage from '../../assets/images/default.svg';

const SearchBank = () => {
    let navigate = useNavigate();
    const [fipList, setFipList] = useState<any[]>([])
    const [banks, setBanks] = useState<any[]>([])
    const [searchValue, SetSearchValue] = useState<any>('');
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        getBanks()
    }, [])

    const getBanks = () => {
        setLoading(true)
        WebService.postAPI({
            action: `/aaclient/fip/search`,
            body: null
        })
            .then((res: any) => {
                setBanks(res.fipList)
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
            })
    }

    const onSearch = (value: any) => {
        WebService.getAPI({
            action: `/aaclient/fip/search/${value}`,
            body: null
        })
            .then((res: any) => {
                setFipList(res.fipList)
            })
            .catch((e) => {

            })

    }

    const getFIPAccounts = (value: any) => {
        setLoading(true)
        WebService.postAPI({
            action: `/aaclient/accounts/search`,
            body: {
                fipEntityid: value.entityId
            }
        })
            .then((res: any) => {
                toast.success(res.message)
                setLoading(false)
                navigate('/add-account', { state: { data: res.accounts, name: value.name, image: value.logo, id: value.entityId } })
            })
            .catch((e) => {
                toast.error(e.response.data.errorMsg)
                setLoading(false)
            })
    }
    return (
        <>
            <Loader show={isLoading} />
            <div className='om-searchac-wrap mt100'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-sm-6 mx-auto my-auto">
                            <div><input className='form-control' type='text' placeholder="Search Bank / Financial Institution " onKeyPress={(e: any) => onSearch(e.target.value)} onKeyUp={(e: any) => SetSearchValue(e.target.value)} /></div>
                            <div className="om-search-box-warp">
                                {
                                    !searchValue && banks?.map((res, index) => {
                                        return (
                                            <div className="om-search-box-inner pointer" key={index} onClick={() => getFIPAccounts(res)}>
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    <img src={res.logo ? res.logo : DefaultImage} alt="No Icon" height={50} width={50} />
                                                </div>
                                                <p className=''>{res.name}</p>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    searchValue && fipList.map((res, index) => {
                                        return (
                                            <div className="om-search-box-inner pointer" key={index} onClick={() => getFIPAccounts(res)}>
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    <img src={res.logo} alt="No Icon" height={50} width={50} />
                                                </div>
                                                <p >{res.name}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )


}

export default SearchBank;