import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import WebService from "../../utility/webService";
import backarrowimg from '../../assets/images/back-arrow.svg'

const HelpAndFeedback = () => {
    const [userDetail, setUserDetail] = useState<any>()
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState('Account')

    useEffect(() => {
        getUserDetail()
    }, [])

    const getUserDetail = () => {
        WebService.getAPI({
            action: `/aaclient/customer`,
            body: null
        })
            .then((res: any) => {
                setUserDetail(res)
                setEmail(res.email)
            })
            .catch((e) => {

            })
    }

    const onSave = () => {
        if (email == '') {
            toast.error('Please enter email')
        } else if (message == '') {
            toast.error('Please enter message')
        } else {
            setLoading(true)
            WebService.postAPI({
                action: `/aaclient/Customer/Feedback`,
                body: {
                    category: selectedCategory,
                    message: message,
                    email: email,
                }
            })
                .then((res: any) => {
                    toast.success(res.message)
                    setMessage('')
                    setSelectedCategory('Account')
                    setLoading(false)
                })
                .catch((e) => {
                    toast.error(e.response.data.errorMsg)
                    setLoading(false)
                })
        }
    }


    return (
        <>
            <section className="mt100 Omeditprofile-wrap">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-sm-12">
                            <div className="white-bg d-block d-sm-none">
                                <a onClick={() => window.history.back()} className="back-arrow"><img src={backarrowimg} alt="Back" /></a>
                                <span>Help and Feedback</span>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 mx-auto my-auto">
                            <h4 className="d-none d-sm-block mt-4">Help and Feedback</h4>
                            <div className="border-btm">
                                <div className="con-title">Full Name</div>
                                <div className="dates">{userDetail?.name}</div>
                            </div>
                            <div className="border-btm">
                                <div className="con-title">Email ID</div>
                                <input className="dates" type='text' style={{ width: '100%', borderWidth: 0 }} placeholder="omsmoney@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="border-btm">
                                <div className="con-title">Message</div>
                                <textarea className="dates" rows={5} style={{ width: '100%', borderWidth: 0 }} placeholder="Enter message" value={message} onChange={(e) => setMessage(e.target.value)} />
                            </div>
                            <div className="border-btm">
                                <div className="con-title">Category</div>
                                <select name="cars" id="cars" onChange={(e: any) => setSelectedCategory(e.target.value)} className='select-dropdown'>
                                    <option value="Account">Account</option>
                                    <option value="Consent">Consent</option>
                                    <option value="Privacy">Privacy</option>
                                    <option value="Others">Others</option>
                                </select>
                            </div>
                            <div className="mt-3 pointer">
                                <a className="tc" onClick={() => window.open('https://www.omsaa.com/grievance')}>View Grievance Policy</a>
                            </div>
                            <div className="form-group mt-5 text-center ">
                                <button type="button" name="" value="save" className="btns" disabled={isLoading} onClick={() => onSave()}>
                                    {isLoading && <div className='om-btn-loader-m'></div>}Save</button>
                            </div>
                            <h4 className="mt-4">Escalation Matrix for Customer Grievance Redressal Mechanism:</h4>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col" className="bg-primary text-white" style={{ verticalAlign: 'top' }}>Escalation</th>
                                        <th scope="col" className="bg-primary text-white" style={{ verticalAlign: 'top' }}>Timeline</th>
                                        <th scope="col" className="bg-primary text-white" style={{ verticalAlign: 'top' }}>Customer / Citizen Action</th>
                                        <th scope="col" className="bg-primary text-white" style={{ verticalAlign: 'top' }}>OMS Fintech Account Aggregator Private Limited Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">Level 1</th>
                                        <td>Day 0 of filing the grievance</td>
                                        <td>Customer sends an email to grievance@omsaa.com with the required details. Registered Office: 501, Olympia, Pune Mumbai Highway, Baner, Pune, MH - 411045</td>
                                        <td>OMS Fintech Account Aggregator Private Limited will send acknowledgement within 24 working hours of receiving the grievance with an aim to resolve the issue within 10 days.</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Level 2</th>
                                        <td>After 10 days</td>
                                        <td>If the grievance is not resolved within 10 days, then customer can reach OMS Fintech Account Aggregator Private Limited Grievance Redressal Officer
                                            Phone: +91 91453 54545
                                            Email:  nitin.sawant@omsaa.com </td>
                                        <td>For, any grievances raised as a Level 2 escalation, OMS Fintech AA Private Limited will ensure such issues / grievances are resolved within next 20 days. OMS Fintech AA Private Limited will keep the customer informed on the progress towards grievance resolution and delays (if any)</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Level 3</th>
                                        <td>After 30 days</td>
                                        <td>As per RBI Master Directions, if the complaint/grievance is not resolved within a period of one month / 30 days from reporting, you may appeal to the Reserve Bank of India (RBI).</td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HelpAndFeedback;