import logo from '../../assets/images/logo.svg';
import mobileIcon from '../../assets/images/phone-icon.svg';
import '../../assets/css/style.css'
import './Login.scss';
import axios from 'axios';
import { BASE_URL } from '../../utility/webService';
import { useForm } from "react-hook-form";
import { Label } from '../../Components/Label/Label';
import { useNavigate } from "react-router-dom";
import HelperService from '../../utility/HelperService';
import { useState } from 'react';

const Login = () => {
    const { register, handleSubmit, formState: { errors }, } = useForm();
    let navigate = useNavigate();
    const [isLoading, setLoading] = useState(false)

    const handler = (e: any) => {
        var keyCode = e ? (e.which ? e.which : e.keyCode) : e.keyCode;
        if (keyCode == 13) {
            document.getElementById('login')?.click()
        }
    }

    const onSubmit = async (data: any) => {
        setLoading(true)
        const headers = {
            'Content-Type': 'application/json',
            'appLang': 'en_US'
        }
        axios.post(`${BASE_URL}/aaclient/login`, data, {
            headers: headers
        })
            .then((res) => {
                setLoading(false)
                navigate("/verify-pin", { state: { mobile: data.mobile, page: 'LOGIN' } });
            })
            .catch((e) => {
                setLoading(false)
                navigate("/signup", { state: { mobile: data.mobile } });
            })
    }
    return (
        <>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-sm-5 mx-auto my-auto">
                        <div className="">
                            <div className="text-center my-5"><img src={logo} alt="OMS AA" width="95" height="99" />
                            </div>
                            <div className="tag-line pb-3">Please Enter Your Mobile Number</div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <div className="input-group input-group-lg">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <img src={mobileIcon} alt="Phone" />
                                            </span>
                                        </div>
                                        <input type="text" className="form-control input" minLength={10} maxLength={10} placeholder="Enter your mobile number" {...register("mobile", { required: true, maxLength: 10, minLength: 10 })}
                                            onKeyPress={(e) =>
                                                HelperService.allowOnlyNumericValue(e)
                                            }
                                            onKeyDown={(e) => handler(e)} />
                                    </div>
                                    {errors.mobile && (
                                        <div style={{ marginLeft: 60 }}>
                                            <Label title={"Please enter mobile number"} modeError={true} />
                                        </div>
                                    )}
                                </div>
                                <div className="sm-title">OMS AA regulated and licensed as NBFC AA</div>
                                <div className="form-group mt-5 btn-fixed">
                                    <button type='submit' className="btns" id='login' disabled={isLoading}>
                                        {isLoading && <div className='om-btn-loader-m'></div>} Procced</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;