import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import { USER_LOGIN_SUCCESS } from '../../action/CommonActions';
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import logo from '../../assets/images/logo.svg';
import searchIcon from '../../assets/images/search-icon.svg';
import backarrowimg from '../../assets/images/back-arrow.svg'

const SearchingAccount = () => {
    let history = useNavigate();
    let location = useLocation();
    const dispatch: Dispatch<any> = useDispatch();

    const onSkip = () => {
        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: { access_token: location.state.token },
        });
        sessionStorage.setItem("token", location.state.token);
        history("/home");
    }
    return (
        <>
            <section className="mt100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-sm-12">
                            <header className="d-none d-sm-block">
                                <nav className="navbar navbar-expand-md navbar-dark fixed-top">
                                    <div className="container">
                                        <a className="navbar-brand">
                                            <img src={logo} alt="" width="70" height="70" />
                                        </a>
                                    </div>
                                </nav>
                            </header>
                            <div className="white-bg d-block d-sm-none">
                                <a onClick={() => window.history.back()} className="back-arrow"><img src={backarrowimg} alt="Back" /></a>
                                <span>Searching Accounts</span>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 mx-auto my-auto">
                            <div className="text-center mt-5"><img src={searchIcon} alt="Searching" width="110" height="110" /></div>
                            <h1 className="search-acct">Searching Accounts</h1>
                            <div className="para">OMS AA identifying your assets using your
                                registered mobile number. This may take upto 60 seconds
                            </div>
                            <div className="my-5 text-center"><a className="skip-link" onClick={() => onSkip()}>Skip to Search via Bank</a></div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default SearchingAccount;